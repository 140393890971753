<template>
  <div class="list-imei">
    <KTCodePreview v-bind:title="'Danh sách mã seri'">
      <!-- <template v-slot:toolbar>
        <div class="row">
          <div>
            <b-dropdown size="sm" id="dropdown-1" right>
              <template slot="button-content">
                <i style="font-size: 1rem" class="fas fa-cog"></i>
                <span class="font-weight-bolder">Thao tác</span>
              </template>
              <b-dropdown-item
                v-if="checkPermission('PRODUCT_EXPORT')"
                @click="excelModal"
              >
                <span>
                  <i style="font-size: 1rem" class="far fa-file-excel"></i>
                  &nbsp; Xuất Excel</span
                >
              </b-dropdown-item>
              <b-dropdown-item @click="$bvModal.show('old-imei-instock-modal')">
                <span>
                  <i style="font-size: 1rem" class="far fa-file-excel"></i>
                  &nbsp; Xuất bảng kê máy cũ còn tồn</span
                >
              </b-dropdown-item>
              <b-dropdown-item
                @click="$bvModal.show('old-imei-outstock-modal')"
              >
                <span>
                  <i style="font-size: 1rem" class="far fa-file-excel"></i>
                  &nbsp; Xuất bảng kê máy cũ đã bán</span
                >
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div v-if="checkPermission('ACTIVE_IMEI')" class="ml-3">
            <router-link to="/imeis/active-imei">
              <b-button v-bind:style="btnCreate" variant="warning" size="sm">
                <i style="font-size: 1rem" class="fa fa-check"></i>Kích hoạt
              </b-button>
            </router-link>
          </div>
          <div v-if="checkPermission('STOCK_UPDATE')" class="ml-3">
            <b-dropdown size="sm" id="dropdown-right" right variant="primary">
              <template slot="button-content">
                <i style="font-size: 1rem" class="fa fa-recycle"></i>
                <span class="font-weight-bolder">Đổi sản phẩm</span>
              </template>
              <b-dropdown-item>
                <router-link to="/imeis/change-product-imei">
                  <span style="color: #3f4254; width: 100%">
                    <i style="font-size: 1rem" class="flaticon2-add-1"></i>
                    &nbsp; Nhập thông tin</span
                  >
                </router-link>
              </b-dropdown-item>
              <b-dropdown-item>
                <router-link to="/imeis/import-change-product-imei">
                  <span style="color: #3f4254">
                    <i style="font-size: 1rem" class="la la-file-excel-o"></i>
                    &nbsp; Nhập từ file excel
                  </span>
                </router-link>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </template> -->
      <template v-slot:preview>
        <b-row class="mb-5">
          <b-col>
            <b-input
              size="sm"
              placeholder="Nhập mã seri"
              v-model="search"
              append-icon="search"
              single-line
              hide-details
              v-on:keyup.enter="onFilter()"
            ></b-input>
          </b-col>
          <b-col>
            <b-input
              size="sm"
              placeholder="Nhập mã, tên sản phẩm"
              v-model="searchProduct"
              append-icon="search"
              single-line
              hide-details
              v-on:keyup.enter="onFilter()"
            ></b-input>
          </b-col>
          <!-- <b-col>
            <treeselect
              :options="filteredCateOptions"
              :multiple="true"
              :searchable="true"
              placeholder="Chọn danh mục sản phẩm"
              :match-keys="['id', 'label']"
              :clearable="true"
              :show-count="true"
              openDirection="bottom"
              v-model="selectedCategories"
            />
          </b-col>
          <b-col>
            <treeselect
              :options="filteredCate2Options"
              :multiple="true"
              :searchable="true"
              placeholder="Chọn danh mục nội bộ"
              :match-keys="['id', 'label']"
              :clearable="true"
              :show-count="true"
              openDirection="bottom"
              v-model="selectedInternalCategories"
            />
          </b-col> -->
          <b-col>
            <BDropdownCustom
              v-model="valueStatus"
              :options="filterdOptionStatus"
              :placeholder="'trạng thái'"
              :matchKey="['name']"
              :searchable="true"
              :limit="5"
            />
          </b-col>
          <b-col>
            <Autosuggest
              :model="searchProvider"
              :suggestions="filteredOptionProvider"
              placeholder="NCC"
              @select="onSelectedProvider"
              @change="onInputChangeProvider"
            />
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col>
            <Autosuggest
              :model="searchStock"
              :suggestions="filteredOptions"
              placeholder="cửa hàng"
              @select="onSelected"
              @change="onInputChangeStore"
            />
          </b-col>
          <b-col>
            <div class="search-col">
              <b-input-group>
                <date-picker
                  placeholder="Từ"
                  class="form-control form-control-sm"
                  :config="dpConfigs.date"
                  v-model="searchFromDay"
                ></date-picker>
              </b-input-group>
              <span class="ml-1 mr-1"></span>
              <b-input-group>
                <date-picker
                  placeholder="Đến"
                  class="form-control form-control-sm"
                  :config="dpConfigs.date"
                  v-model="searchToDay"
                ></date-picker>
              </b-input-group>
            </div>
          </b-col>

          <b-col></b-col>
          <b-col></b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col md="1">
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              @click="onFilter"
              >Lọc</b-button
            >
          </b-col>
          <b-col>
            <div class="d-flex justify-content-end">
              <p
                class="mt-3 text-dark"
                style="font-weight: 500; font-size: 13px"
              >
                {{ fromNo }} - {{ toNo }} /
                {{ convertPrice(totalItem) }}
              </p>
            </div>
          </b-col>
        </b-row>

        <b-table
          :items="listImeis"
          :fields="fields"
          bordered
          hover
          :busy="onLoading"
        >
          <template v-slot:table-busy>
            <vcl-table :speed="5" :animate="true" :columns="10"></vcl-table>
          </template>
          <template v-slot:head(selected)>
            <span>
              <b-form-checkbox
                v-model="checkAllPro"
                @change="checkAll"
                size="lg"
              ></b-form-checkbox>
            </span>
          </template>
          <template v-slot:cell(selected)="row">
            <div class="d-flex justify-content-center">
              <b-form-checkbox
                size="lg"
                v-model="row.item.selected"
                @change="clickIndex"
              ></b-form-checkbox>
            </div>
          </template>
          <template v-slot:cell(productName)="row">
            <span
              v-if="row.item.barCode"
              v-text="row.item.barCode"
              style="white-space: normal; font-size: 12px; font-weight: 500"
            ></span>
            <p
              v-if="row.item.productCode"
              style="font-size: 12px; font-weight: 500; margin-bottom: 0"
            >
              ( {{ row.item.productCode }} )
            </p>
            <span
              v-text="row.item.productName"
              style="white-space: normal"
            ></span>
            <div>
              <MerchantLabel :merchantId="row.item.merchantId" />
            </div>
          </template>
          <template v-slot:cell(provider)="row">
            <p
              v-if="row.item.provider"
              style="font-size: 13px; font-weight: 4500; margin-bottom: 0"
            >
              {{ row.item.provider }}
            </p>
            <span
              v-text="formatDate(row.item.createdAt)"
              style="white-space: normal; color: blue"
            ></span>
          </template>
          <template v-slot:cell(serialNumber)="row">
            <div class="row">
              <div class="imeiCode col-md-10" style="cursor: pointer">
                <!-- <div
                class="imeiCode col-md-10"
                @click="linkToImeiHistory(row.item)"
                style="cursor: pointer"
              > -->
                <span v-text="row.item.serialNumber"></span>
              </div>
              <i
                class="fas fa-copy ml-4"
                style="cursor: pointer; font-size: 11px"
                v-clipboard:copy="row.item.serialNumber"
                title="Copy to clipboard"
                @click="makeToastSuccess('Copied to clipboard!')"
              ></i>
            </div>
          </template>
          <template v-slot:cell(status)="row">
            <span
              v-text="checkStatus(row.item.status)"
              class="label font-weight-bold label-lg label-inline"
              v-bind:class="getStatusColor(row.item.status)"
              style="width: max-content"
            ></span>
          </template>
          <template v-slot:cell(importPrice)="row">
            <div style="text-align: end">
              <span v-text="convertPrice(row.item.importPrice)"></span>
            </div>
          </template>
          <template v-slot:cell(sellingPrice)="row">
            <div
              @mouseover="hoverPrice = true"
              @mouseleave="hoverPrice = false"
              style="text-align: end; cursor: pointer"
            >
              <span @click="handleShowPriceModel(row.item)">
                <i v-if="hoverPrice" class="fas fa-edit"></i>
              </span>

              <span v-text="convertPrice(row.item.sellingPrice)"></span>
            </div>
          </template>
          <!-- <template v-slot:cell(actions)="row">
            <div class="d-flex justify-content-center">
              <b-dropdown size="sm" id="dropdown-left" no-caret right>
                <template slot="button-content">
                  <i
                    style="font-size: 1rem; padding-right: 0px"
                    class="flaticon2-settings"
                  ></i>
                </template>
                <b-dropdown-item
                  @click="editItem(row.item)"
                  v-if="checkViewOnly()"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 1rem" class="flaticon-eye"></i>
                    &nbsp; Chi tiết sản phẩm
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="editItem(row.item)"
                  v-if="!checkViewOnly()"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 1rem" class="flaticon2-pen"></i>
                    &nbsp; Chỉnh sửa
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showActiveModal(row.item.imeiCode)"
                  v-if="!checkViewOnly()"
                  v-show="checkPermission('ACTIVE_IMEI')"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 1rem" class="fa fa-check"></i>
                    &nbsp; Kích hoạt
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template> -->
        </b-table>
        <template>
          <b-modal
            v-model="showEditPriceModal"
            hide-footer
            :title="productName"
          >
            <b-row class="mb-5">
              <b-col>
                <label class="labelInput" for="inputOriginalPrice"
                  >Giá nhập</label
                >
                <b-form-input
                  v-model="originalPrice"
                  size="sm"
                  placeholder="Nhập giá nhập"
                  v-mask="mask"
                ></b-form-input>
              </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col>
                <label class="labelInput" for="inputSellingPrice"
                  >Giá bán</label
                >
                <b-form-input
                  v-model="sellingPrice"
                  size="sm"
                  placeholder="Nhập giá bán"
                  v-mask="mask"
                ></b-form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-button
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  @click="updateProduct"
                  >Lưu</b-button
                >
                <b-button
                  style="margin-left: 10px; font-weight: 600; width: 70px"
                  variant="secondary"
                  size="sm"
                  @click="handleShowPriceModel"
                  >Hủy</b-button
                >
              </b-col>
            </b-row>
          </b-modal>
        </template>
        <b-row>
          <b-col>
            <p class="mt-3 text-dark" style="font-weight: 500">
              Tổng số:
              {{ convertPrice(totalItem) }}
            </p>
          </b-col>
          <div class="d-flex justify-content-end">
            <b-col>
              <b-pagination-nav
                class="custom-pagination"
                v-if="numberOfPage >= 2"
                :link-gen="linkGen"
                :number-of-pages="numberOfPage"
                use-router
                @change="fetchData"
                align="right"
                first-class="page-item-first btn btn-icon btn-sm m-1"
                prev-class="page-item-prev btn btn-icon btn-sm m-1"
                next-class="page-item-next btn btn-icon btn-sm m-1 "
                last-class="page-item-last btn btn-icon btn-sm m-1 "
                page-class="btn btn-icon btn-sm border-0 m-1"
              >
                <template v-slot:first-text>
                  <span>
                    <i class="ki ki-double-arrow-back icon-xs"></i>
                  </span>
                </template>

                <template v-slot:prev-text>
                  <i class="ki ki-arrow-back icon-xs"></i>
                </template>

                <template v-slot:next-text>
                  <i class="ki ki-arrow-next icon-xs"></i>
                </template>

                <template v-slot:last-text>
                  <span class="text-info">
                    <i class="ki ki-double-arrow-next icon-xs"></i>
                  </span>
                </template>
              </b-pagination-nav>
            </b-col>
          </div>
        </b-row>
      </template>
    </KTCodePreview>
    <b-modal
      ref="excel-modal"
      hide-footer
      title="Xuất excel danh sách imei"
      id="excel-modal"
    >
      <v-form ref="form" lazy-validation>
        <div class="mb-4">
          <div class="row">
            <div class="col-6">
              <b-form-group label="Chọn cột cần xuất:">
                <b-form-radio-group
                  v-model="selectedRowExcel"
                  name="radio-sub-component-1"
                  @change.native="showHeader"
                >
                  <b-form-radio value="all">Tất cả</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
              <b-form-group label="Danh sách cần xuất:">
                <b-form-radio-group
                  v-model="selectedListExcel"
                  name="radio-sub-component-2"
                >
                  <b-form-radio value="all">Tất cả các trang</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </div>
          </div>
        </div>

        <b-button
          style="width: 110px"
          variant="primary"
          size="sm"
          @click="reportClick"
          class="mr-3"
        >
          <v-icon small class="text-white" v-b-tooltip>mdi-download</v-icon>
          <strong>Xuất Excel</strong>
        </b-button>

        <b-button
          style="width: 100px"
          variant="secondary"
          size="sm"
          @click="$bvModal.hide('excel-modal')"
        >
          <strong>Hủy</strong>
        </b-button>
      </v-form>
    </b-modal>
    <b-modal
      ref="old-imei-instock-modal"
      hide-footer
      title="Xuất bảng kê máy cũ"
      id="old-imei-instock-modal"
    >
      <v-form>
        <b-form-group>
          <label for="list-input-old-imei">Chọn cửa hàng</label>
          <Autosuggest
            :model="searchStoreForOldImeiList"
            :suggestions="suggestionStoreForOldImeiList"
            @select="onSelectedStoreForOldImeiList"
          />
        </b-form-group>
        <b-button
          style="width: 110px"
          variant="primary"
          size="sm"
          @click="reportListOldImeis"
          class="mr-3"
        >
          <v-icon small class="text-white" v-b-tooltip>mdi-download</v-icon>
          <strong>Xuất excel</strong>
        </b-button>

        <b-button
          style="width: 100px"
          variant="secondary"
          size="sm"
          @click="$bvModal.hide('old-imei-instock-modal')"
        >
          <strong>Hủy</strong>
        </b-button>
      </v-form>
    </b-modal>
    <b-modal
      ref="old-imei-outstock-modal"
      hide-footer
      title="Xuất bảng kê máy cũ đã bán"
      id="old-imei-outstock-modal"
    >
      <v-form>
        <b-form-group>
          <label for="list-input-old-imei">Danh sách imei cần tìm:</label>
          <b-form-textarea
            id="list-input-old-imei"
            v-model="inputListOldImei"
            placeholder="Có thể nhập nhiều imei trên mỗi dòng"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-form-group>
        <b-button
          style="width: 110px"
          variant="primary"
          size="sm"
          @click="reportOutstockOldImeiList"
          class="mr-3"
        >
          <v-icon small class="text-white" v-b-tooltip>mdi-download</v-icon>
          <strong>Xuất excel</strong>
        </b-button>

        <b-button
          style="width: 100px"
          variant="secondary"
          size="sm"
          @click="$bvModal.hide('old-imei-outstock-modal')"
        >
          <strong>Hủy</strong>
        </b-button>
      </v-form>
    </b-modal>
    <ActiveImei ref="active-imei-modal" />
  </div>
</template>

<style lang="scss">
.list-imei {
  .labelInput {
    font-weight: 600;
  }
  .productCode:hover {
    text-decoration: underline;
  }

  .inputText {
    width: 22%;
  }

  .page-item.disabled {
    cursor: not-allowed;
    &.bv-d-xs-down-none {
      .page-link {
        margin: 0.35rem 0.25rem;
        text-align: center;
      }
    }
  }

  .icon:hover {
    background-color: #90c6fc;
  }

  .selectedProClass {
    width: 4%;
    text-align: center;
  }
  .productNameClass {
    width: 20%;
  }
  .productNameNotPriceClass {
    width: 20%;
  }
  .imeiClass {
    width: 15%;
  }
  .providerClass {
    width: 15%;
  }
  .storeClass {
    width: 10%;
    align-items: center;
  }
  .importPriceProClass {
    width: 10%;
  }
  .salePriceProClass {
    width: 10%;
  }
  .statusProClass {
    width: 10%;
    text-align: center;
  }
  .dropdown-header {
    color: #3699ff !important;
  }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: middle;
    border-top: 1px solid #ecf0f3;
  }
  .table.b-table.table-sm
    > thead
    > tr
    > [aria-sort]:not(.b-table-sort-icon-left),
  .table.b-table.table-sm
    > tfoot
    > tr
    > [aria-sort]:not(.b-table-sort-icon-left) {
    background-position: right calc(1.5rem / 2) center;
    padding-right: calc(0.3rem + 0.65em);
  }
  a {
    width: 100%;
  }

  .imeiCode:hover {
    text-decoration: underline;
  }
  .test {
    position: absolute;
    right: 1vw;
  }
  .checkbox-label {
    display: block;
  }

  .search-col {
    display: flex;
    align-items: center;
  }
}
</style>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import Swal from 'sweetalert2';
import ApiService from '@/core/services/api.service';
import localData from '@/utils/saveDataToLocal';
import { VclTable } from 'vue-content-loading';
import {
  currencyMask,
  unMaskPrice,
  xoa_dau,
  removeAccents,
  convertPrice,
  makeToastSuccess,
  makeToastFaile,
} from '@/utils/common';
import axios from 'axios';
import decounce from 'debounce';
import { BASE_URL, TIME_TRIGGER } from '@/utils/constants';
import fileDownload from '@/utils/file-download';
import ActiveImei from '@/view/components/imeis/ActiveImei';
import MerchantLabel from '@/view/components/MerchantLabel';

import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import { cloneDeep, map, find, assign } from 'lodash';
import { getToastInstance } from '@/utils/toastHelper';
import BDropdownCustom from '@/view/base/bootstrap/BDropdownCustom.vue';
// import Treeselect from '@riophae/vue-treeselect';
// import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { getStartOfDay, getEndOfDay } from '@/utils/date';
import { IMEI_STATUS } from '@/utils/enum';
import moment from 'moment';

export default {
  data() {
    return {
      selectedCategories: [],
      selectedInternalCategories: [],
      inputListOldImei: '',
      originalPrice: 0,
      sellingPrice: 0,
      productName: '',
      productId: '',
      hoverPrice: false,
      selectedProvider: null,
      listProvider: [],
      btnCreate: {
        fontWeight: '600!important',
      },
      selected: [],
      checkAllPro: false,
      fields: [
        {
          key: 'selected',
          label: '',
          thStyle: { textAlign: 'center' },
          tdClass: 'selectedProClass',
          thClass: 'selectedProClass',
        },
        {
          key: 'productName',
          label: 'Sản phẩm',
          thStyle: { textAlign: 'center', color: 'rgb(24, 28, 50)' },
          tdClass: 'productNameClass',
          thClass: 'productNameClass',
        },
        {
          key: 'serialNumber',
          label: 'Mã Seri',
          thStyle: { textAlign: 'center', color: 'rgb(24, 28, 50)' },
          tdClass: 'imeiClass',
          thClass: 'imeiClass',
        },
        {
          key: 'provider',
          label: 'Nhà cung cấp',
          thStyle: { textAlign: 'center', color: 'rgb(24, 28, 50)' },
          tdClass: 'providerClass',
          thClass: 'providerClass',
        },
        {
          key: 'store',
          label: 'Kho',
          thStyle: { textAlign: 'center', color: 'rgb(24, 28, 50)' },
          tdClass: 'storeClass',
          thClass: 'storeClass',
        },
        {
          key: 'importPrice',
          label: 'Giá nhập',
          thStyle: { textAlign: 'center', color: 'rgb(24, 28, 50)' },
          tdClass: 'importPriceProClass',
          thClass: 'importPriceProClass',
        },
        {
          key: 'sellingPrice',
          label: 'Giá bán',
          thStyle: { textAlign: 'center', color: 'rgb(24, 28, 50)' },
          tdClass: 'salePriceProClass',
          thClass: 'salePriceProClass',
        },
        {
          key: 'status',
          label: 'Trạng thái',
          thStyle: { textAlign: 'center', color: 'rgb(24, 28, 50)' },
          tdClass: 'statusProClass',
          thClass: 'statusProClass',
        },
        // { key: 'actions', label: '' },
      ],
      search: '',
      listImeis: [],
      listCategories: [],
      selectedCategory: null,
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      page: 1,
      totalItem: 0,
      listStatus: [
        { id: '', name: 'Tất cả trạng thái' },
        { id: IMEI_STATUS.NEW, name: 'Mới' },
        { id: IMEI_STATUS.SALED, name: 'Đã bán' },
        { id: IMEI_STATUS.TRANSFERING, name: 'Đang vận chuyển' },
        { id: IMEI_STATUS.ERROR, name: 'Lỗi' },
        { id: IMEI_STATUS.RETURN_TO_SUPPLIER, name: 'Đã trả NCC' },
        { id: IMEI_STATUS.STOCK_TRANSFER, name: 'Đang chuyển kho' },
        { id: IMEI_STATUS.EXPORT_WARRANTY, name: 'Đã trả bảo hành' },
        { id: IMEI_STATUS.ON_WARRANTY, name: 'Đang bảo hành' },
        { id: IMEI_STATUS.ON_HOLDING, name: 'Đang giữ hàng' },
        { id: IMEI_STATUS.ON_LENDING, name: 'Đang cho mượn' },
        { id: IMEI_STATUS.LOSS, name: 'Thất thoát' },
      ],
      onLoading: false,
      numberOfPage: 0,
      filteredOptions: [],
      suggestionStoreForOldImeiList: [],
      optionsStore: [
        {
          data: [],
        },
      ],
      searchProduct: '',
      showEditPriceModal: false,
      mask: currencyMask,
      listStore: [],
      selectedStore: null,
      selectedStoreForOldImeiList: null,
      searchStock: '',
      searchStoreForOldImeiList: '',
      selectedRowExcel: 'all',
      selectedListExcel: 'all',
      showHeaderExcel: false,
      indeterminate: false,
      filteredOptionProvider: [],
      optionsProvider: [
        {
          data: [],
        },
      ],
      searchProvider: '',
      fromNo: 0,
      toNo: 0,
      LIMIT_PAGE: 30,
      cateOptions: [
        {
          data: [],
        },
      ],
      filteredCateOptions: [],
      selectedCateId: null,
      cate2Options: [
        {
          data: [],
        },
      ],
      filteredCate2Options: [],
      selectedCate2Id: null,
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      searchFromDay: '',
      searchToDay: '',
      valueStatus: [],
      optionsStatus: [
        {
          code: 'NEW',
          name: 'Mới',
          shortName: 'Mới',
          checked: false,
        },
        {
          code: 'SALED',
          name: 'Đã bán',
          shortName: 'Đã bán',
          checked: false,
        },
        {
          code: 'TRANSFERING',
          name: 'Đang vận chuyển',
          shortName: 'Đang vận chuyển',
          checked: false,
        },
        {
          code: 'ERROR',
          name: 'Lỗi',
          shortName: 'Lỗi',
          checked: false,
        },
        {
          code: 'RETURN_TO_SUPPLIER',
          name: 'Đã trả NCC',
          shortName: 'Đã trả NCC',
          checked: false,
        },
        {
          code: 'STOCK_TRANSFER',
          name: 'Đang chuyển kho',
          shortName: 'Đang chuyển kho',
          checked: false,
        },
        {
          code: 'EXPORT_WARRANTY',
          name: 'Đã trả bảo hành',
          shortName: 'Đã trả bảo hành',
          checked: false,
        },
        {
          code: 'ON_WARRANTY',
          name: 'Đang bảo hành',
          shortName: 'Đang bảo hành',
          checked: false,
        },
        {
          code: 'ON_HOLDING',
          name: 'Đang giữ hàng',
          shortName: 'Đang giữ hàng',
          checked: false,
        },
        {
          code: 'ON_LENDING',
          name: 'Đang cho mượn',
          checked: false,
        },
        {
          code: 'LOSS',
          name: 'Thất thoát',
          checked: false,
        },
      ],
      filterdOptionStatus: [],
    };
  },
  components: {
    KTCodePreview,
    VclTable,
    ActiveImei,
    Autosuggest,
    MerchantLabel,
    BDropdownCustom,
    // Treeselect,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Danh sách mã seri' }]);
  },
  created() {
    let imeiCode = this.$route.query.code;
    let searchProduct = this.$route.query.searchProduct;
    let storeId = this.$route.query.storeId;
    if (imeiCode !== undefined) {
      this.search = imeiCode;
    }
    if (searchProduct !== undefined) {
      this.searchProduct = searchProduct;
    }
    if (storeId !== undefined) {
      this.selectedStore = storeId;
    }
    this.fetchData();
    this.fetchProvider();
    this.checkViewOriginalPrice();
    this.fetchStore();
    this.fetchCategory();
    this.fetchInternalCategory();
  },
  methods: {
    formatDate: function (dateVal) {
      const date = moment(dateVal);
      return date.format('DD/MM/yyyy');
    },
    getStatusColor(status) {
      const MAP_COLOR = {
        NEW: 'label-light-warning',
        SALED: 'label-light-success',
        TRANSFERING: 'label-light-danger',
        ERROR: 'label-light-default',
        LENDING: 'label-light-info',
      };

      return MAP_COLOR[status] || 'label-light-default';
    },
    convertPrice,
    ...getToastInstance(),
    checkStatus: function (item) {
      switch (item) {
        case 'NEW':
          return 'Mới';
        case 'SALED':
          return 'Đã bán';
        case 'TRANSFERING':
          return 'Đang vận chuyển';
        case 'ERROR':
          return 'Lỗi';
        case 'RETURN_TO_SUPPLIER':
          return 'Đã trả NCC';
        case 'STOCK_TRANSFER':
          return 'Đang chuyển kho';
        case 'EXPORT_WARRANTY':
          return 'Đã trả bảo hành';
        case 'ON_WARRANTY':
          return 'Đang bảo hành';
        case 'ON_HOLDING':
          return 'Đang giữ hàng';
        case 'ON_LENDING':
          return 'Đang cho mượn';
        case 'LOSS':
          return 'Thất thoát';
        default:
          return '';
      }
    },
    checkViewOnly: function () {
      let count = 0;
      if (localData.checkPermission('PRODUCT_VIEW')) {
        count++;
      }
      if (localData.checkPermission('PRODUCT_UPDATE')) {
        count++;
      }
      if (count === 1) {
        return true;
      } else {
        return false;
      }
    },
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa sản phẩm!',
        text: 'Bạn có chắc muốn xóa sản phẩm này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        position: 'top',
      }).then((result) => {
        if (result.value) {
          this.deleteItem(item);
        }
      });
    },
    deleteItem: function (item) {
      alert(item);
    },
    fetchData: function () {
      this.onLoading = true;
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }
      if (this.searchStock.trim() === '') {
        this.selectedStore = null;
      }
      let listStatus = [];
      if (this.valueStatus.length) {
        listStatus = this.valueStatus.map(({ code }) => code);
      }
      this.filterdOptionStatus = cloneDeep(this.optionsStatus);
      const param = {
        page: this.page,
        limit: this.LIMIT_PAGE,
        status: listStatus,
        search: this.search ? this.search.trim() : '',
        providerId: this.selectedProvider,
        searchName: this.searchProduct,
        storeId: this.selectedStore,
        fromDate: getStartOfDay(this.searchFromDay),
        toDate: getEndOfDay(this.searchToDay),
        // cateId: this.selectedCategories,
        // internalCateId: this.selectedInternalCategories,
      };
      const paramQuery = {
        params: param,
      };
      ApiService.query('products/serial', paramQuery)
        .then(({ data }) => {
          this.listImeis = [];
          this.numberOfPage = data.data.paginations.totalPages;
          this.totalItem = data.data.paginations.total;
          this.fromNo = (this.page - 1) * this.LIMIT_PAGE + 1;
          this.toNo = this.LIMIT_PAGE * this.page;
          if (this.toNo > this.totalItem) {
            this.toNo = this.totalItem;
          }
          data.data.data.forEach((element) => {
            let item = {
              id: element.id,
              // barCode: element.barCode,
              productCode: element.product.code,
              productName: element.product.name,
              serialNumber: element.serialNumber,
              provider: element.provider.name,
              store: element.store.name,
              importPrice: element.importPrice,
              sellingPrice: element.sellingPrice,
              status: element.status,
              selected: false,
              createdAt: element.createdAt,
            };
            this.listImeis.push(item);
          });
          this.onLoading = false;
        })
        .catch((error) => {
          this.onLoading = false;
          if (error.response) {
            makeToastFaile(
              error.response.data ? error.response.data.message : 'Lỗi',
            );
          } else {
            makeToastFaile(error.message || 'Lỗi');
          }
        });
    },
    editItem: function (item) {
      this.$router.push({
        name: 'update-imei',
        query: { id: item.id },
      });
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    fetchProvider() {
      ApiService.setHeader();
      ApiService.get(`providers?limit=1000000`).then(({ data }) => {
        this.listProvider = data.data.data;
        let noneProvider = {
          id: null,
          name: 'Tất cả',
        };
        this.listProvider.unshift(noneProvider);
        this.listProvider.map((element) => {
          this.optionsProvider[0].data.push(element);
        });
        this.filteredOptionProvider = [...this.optionsProvider[0].data];
      });
    },
    onSelected(option) {
      this.searchStock = option.item.name;
      this.selectedStore = option.item.id;
      this.onFilter();
    },
    async reportListOldImeis() {
      await axios({
        url: `${BASE_URL}stocks/export-list-old-imeis?storeIds=${this.selectedStoreForOldImeiList}`,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        const fileName = fileDownload.getFileName(response);
        fileDownload.createFile(response.data, fileName);
      });
    },
    async reportOutstockOldImeiList() {
      await axios({
        url: `${BASE_URL}stocks/export-list-outstock-old-imeis`,
        method: 'POST',
        responseType: 'blob',
        data: {
          listImeis: this.inputListOldImei,
        },
      }).then((response) => {
        const fileName = fileDownload.getFileName(response);
        fileDownload.createFile(response.data, fileName);
      });
    },
    onSelectedStoreForOldImeiList(option) {
      this.searchStoreForOldImeiList = option.item.name;
      this.selectedStoreForOldImeiList = option.item.id;
    },
    onInputChangeStore(text) {
      if (!text) {
        text = '';
      }
      this.searchStock = text;

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredData = this.optionsStore[0].data.filter((item) => {
        return (
          item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
          item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
        );
      });

      this.filteredOptions = [...filteredData];
    },
    getSuggestionValue(suggestion) {
      return suggestion.item.name;
    },
    handleShowPriceModel(item) {
      this.showEditPriceModal = !this.showEditPriceModal;
      this.productId = item.id;
      this.productName = item.productName;
      this.originalPrice = item.originalPrice;
      this.sellingPrice = item.sellingPrice;
    },
    updateProduct: async function () {
      const data = {
        id: this.productId,
        originalPrice: unMaskPrice(this.originalPrice),
        sellingPrice: unMaskPrice(this.sellingPrice),
      };
      await ApiService.put('imeis/update-price', data)
        .then(({ data }) => {
          if (data.status === 1) {
            this.fetchData();
            this.showEditPriceModal = !this.showEditPriceModal;
            makeToastSuccess(data.message);
          } else {
            this.fetchData();
            this.showEditPriceModal = !this.showEditPriceModal;
            makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          const { status, message } = response.data;
          if (status === 0) {
            makeToastFaile(message);
          }
        });
    },
    linkToImeiHistory: function (item) {
      const { href } = this.$router.resolve({
        name: 'list-imei-history',
        query: { code: item.imeiCode },
      });
      window.open(href, '_blank');
    },
    excelModal: async function () {
      this.$refs['excel-modal'].show();
    },
    reportClick: async function () {
      this.processBar = 0;
      const listStatus = this.valueStatus.map((element) => element.code);
      const params = {
        status: listStatus,
        search: this.search,
        providerId: this.selectedProvider,
        searchProduct: this.searchProduct,
        storeId: this.selectedStore,
        fromDate: getStartOfDay(this.searchFromDay),
        toDate: getEndOfDay(this.searchToDay),
        cateId: this.selectedCategories,
        internalCateId: this.selectedInternalCategories,
      };

      try {
        const response = await ApiService.query(
          'productEmeiStock/export-excel',
          { params, responseType: 'blob' },
        );
        const fileName = fileDownload.getFileName(response);
        fileDownload.createFile(response.data, fileName);
      } catch (error) {
        const responseObj = JSON.parse(await error.response.data.text());
        return makeToastFaile(responseObj.message || 'Lỗi');
      }
    },
    debounceInput: decounce(function () {
      this.onFilter();
    }, TIME_TRIGGER),
    checkViewOriginalPrice() {
      if (!this.checkPermission('PRODUCT_ORIGINAL_PRICE_VIEW')) {
        let listNew = this.fields.filter(
          (item) => item.key !== 'originalPrice',
        );
        this.fields = [];
        listNew.forEach((element) => {
          this.fields.push(element);
        });
        for (let index = 0; index < this.fields.length; index++) {
          const element = this.fields[index];
          if (element.key === 'productName') {
            this.fields[index].tdClass = 'productNameNotPriceClass';
            this.fields[index].thClass = 'productNameNotPriceClass';
            break;
          }
        }
      }
    },
    fetchStore: async function () {
      this.optionsStore[0].data = [];
      ApiService.get('/stores?limit=10000000').then((response) => {
        const stores = response.data.data.data;
        stores.map((element) => {
          this.optionsStore[0].data.push(element);
        });
        this.filteredOptions = [...this.optionsStore[0].data];
        this.suggestionStoreForOldImeiList = [...this.optionsStore[0].data];
      });
    },
    onFilter() {
      this.$route.query.page = 1;
      // this.$router.push({
      //   name: 'list-imeis',
      // });
      this.fetchData();
    },
    showHeader() {
      if (this.selectedRowExcel === 'row') {
        this.showHeaderExcel = true;
      } else {
        this.showHeaderExcel = false;
      }
    },
    onSelectedProvider(option) {
      this.searchProvider = option.item.name;
      this.selectedProvider = option.item.id;
    },
    onInputChangeProvider(text) {
      if (!text) {
        text = '';
      }
      this.searchProvider = text;

      const filteredData = this.optionsProvider[0].data.filter((item) => {
        let itemName = xoa_dau(item.name);
        return itemName.toLowerCase().indexOf(text.toLowerCase()) > -1;
      });

      this.filteredOptionProvider = [...filteredData];
    },
    fetchCategory: function () {
      const param = {
        page: 1,
        limit: 1000,
        name: '',
        code: '',
      };
      const paramQuery = {
        params: param,
      };
      ApiService.query('category/get-list-category', paramQuery).then(
        ({ data }) => {
          this.filteredCateOptions = data.data.list_caterogy;
        },
      );
    },
    fetchInternalCategory: function () {
      const param = {
        page: 1,
        limit: 1000,
        name: '',
        code: '',
      };
      const paramQuery = {
        params: param,
      };
      ApiService.query(
        'productExternalCategory/get-list-category',
        paramQuery,
      ).then(({ data }) => {
        this.filteredCate2Options = data.data.list_caterogy;
      });
    },
    onInputStatusChange(textInput = '') {
      this.searchStatus(textInput);
    },
    searchStatus(textInput) {
      let options = cloneDeep(this.optionsStatus);
      if (!textInput || !textInput.trim().length) {
        this.filterdOptionStatus = map(options, (obj) => {
          return assign(obj, find(this.filterdOptionStatus, { id: obj.id }));
        });
        return;
      }

      const indexChooseAll = options.findIndex((prop) => prop.id === -1);

      if (indexChooseAll > -1) {
        options.splice(indexChooseAll, 1);
      }

      options = map(options, (obj) => {
        return assign(obj, find(this.filterdOptionStatus, { id: obj.id }));
      });

      this.filterdOptionStatus = this.fitlerOptionsBy(
        options,
        textInput,
        'name',
        10,
      );
    },
    fitlerOptionsBy(items, textInput, prop, limit) {
      return cloneDeep(items)
        .filter((item) => {
          if (item) {
            const nameWTUnicode = removeAccents(item[prop] || '');
            const nameInputWTUnicode = removeAccents(textInput);
            const index = nameWTUnicode
              .toLowerCase()
              .indexOf(nameInputWTUnicode.toLowerCase());

            if (index > -1) {
              return true;
            }
          }
          return false;
        })
        .slice(0, limit);
    },
    onSelectStatus(option) {
      const index = this.optionsStatus.findIndex(
        (item) => item.name == option.name,
      );
      this.optionsStatus[index].checked = true;
      this.filterdOptionStatus = cloneDeep(this.optionsStatus);
    },
    onRemoveStatus(option) {
      const index = this.optionsStatus.findIndex(
        (item) => item.name == option.name,
      );
      this.optionsStatus[index].checked = false;
      this.filterdOptionStatus = cloneDeep(this.optionsStatus);
    },
    customLabelStatus(option) {
      return `${option.name}`;
    },
    showActiveModal(imeiCode) {
      this.$refs['active-imei-modal'].showModal(imeiCode);
    },
    checkAll() {
      this.listImeis.forEach((element) => {
        element.selected = this.checkAllPro;
      });
    },
    clickIndex() {
      if (this.checkAllPro == true) {
        this.checkAllPro = false;
      } else {
        var count = 0;
        this.listImeis.forEach((element) => {
          if (element.selected == true) {
            count += 1;
            if (count == 10) {
              this.checkAllPro = true;
            }
          }
        });
      }
    },
  },
};
</script>
